export const LONG_DESCRIPTION = "script_long_description";
export const EXAMPLE_USE_CASES = "script_example_use_cases";
export const WHAT_YOU_NEED = "script_what_you_need";
export const FREQUENTLY_ASKED = "script_frequently_asked";
export const HOW_IT_WORKS = "script_how_it_works";
export const VARIABLES_TO_UPDATE = "script_variables_to_update_bo";
export const NOTES_DETAILS = "script_notes_details_bo";

export const WYSIWYG_FIELD_NAMES = [
  LONG_DESCRIPTION, 
  EXAMPLE_USE_CASES,
  WHAT_YOU_NEED,
  FREQUENTLY_ASKED,
  HOW_IT_WORKS,
  VARIABLES_TO_UPDATE,
  NOTES_DETAILS
]

export const HELP_FILED = [
  {
    name: LONG_DESCRIPTION,
    id: "EditListingDetailsForm.helpLongDes"
  },
  {
    name: EXAMPLE_USE_CASES,
    id: "EditListingDetailsForm.helpExampleUse"
  },
  {
    name: WHAT_YOU_NEED,
    id: "EditListingDetailsForm.helpWhatNeed"
  },
  {
    name: FREQUENTLY_ASKED,
    id: "EditListingDetailsForm.helpFreequently"
  },
  {
    name: HOW_IT_WORKS,
    id: "EditListingDetailsForm.helpHowItWorks"
  },
  {
    name: VARIABLES_TO_UPDATE,
    id: "EditListingDetailsForm.helpVariablesToUpdate"
  },
  {
    name: NOTES_DETAILS,
    id: "EditListingDetailsForm.helpImportantNotes"
  }
]

export const ONY_FOR_BUYERS =[HOW_IT_WORKS, VARIABLES_TO_UPDATE, NOTES_DETAILS]